import { produce } from 'immer';
import { NOT_FOUND } from 'redux-first-router';
import {
  ROUTE_ABOUT,
  ROUTE_ACCOUNT_RECOVERY,
  ROUTE_ACCOUNT_VALIDATION,
  ROUTE_AFFILIATE_PROGRAM,
  ROUTE_ARTICLE,
  ROUTE_CAREERS,
  ROUTE_COMPANY,
  ROUTE_COMPANY_WITH_EVENTS,
  ROUTE_COMPARE_COMPANY,
  ROUTE_COMPARE_HOME,
  ROUTE_CREATE_CRITERIA,
  ROUTE_DASHBOARD,
  ROUTE_DASHBOARD_WITH_EVENTS,
  ROUTE_DELETE_ACCOUNT,
  ROUTE_DEPRECATED_IMPORT_PORTFOLIO,
  ROUTE_DEPRECATED_PORTFOLIO,
  ROUTE_DEPRECATED_PORTFOLIO_EDIT_TXN,
  ROUTE_DEPRECATED_PORTFOLIO_HOME,
  ROUTE_DEPRECATED_PORTFOLIO_LIST,
  ROUTE_DEPRECATED_PORTFOLIO_RETURNS_REPORT,
  ROUTE_DEPRECATED_PORTFOLIO_SHARE,
  ROUTE_DEPRECATED_PUBLIC_PORTFOLIO,
  ROUTE_MY_PORTFOLIOS,
  ROUTE_DISCOVER_INVESTING_IDEAS,
  ROUTE_DISCOVER_INVESTING_IDEAS_MARKET,
  ROUTE_DISCOVER_SAMPLE_PORTFOLIOS,
  ROUTE_EDIT_EXPLORE_IDEA,
  ROUTE_EDIT_USER_IDEA,
  ROUTE_HOME,
  ROUTE_INVESTING_IDEA_MARKET,
  ROUTE_INVESTING_IDEA_MARKET_INDUSTRY,
  ROUTE_MAINTENANCE,
  ROUTE_MARKET,
  ROUTE_MARKET_HOME,
  ROUTE_MARKET_HUB,
  ROUTE_MARKET_SECTOR,
  ROUTE_MARKET_SECTOR_SECONDARY,
  ROUTE_MARKET_SECTOR_SECONDARY_TERTIARY,
  ROUTE_NARRATIVE,
  ROUTE_OOPS,
  ROUTE_OTP_LOGIN,
  ROUTE_PLANS,
  ROUTE_PARTNER_REGISTER,
  ROUTE_PARTNER_LOGIN,
  ROUTE_PARTNER_ERROR,
  ROUTE_PODCASTS,
  ROUTE_PORTFOLIO,
  ROUTE_PORTFOLIO_DEFAULT,
  ROUTE_PORTFOLIO_DEMO,
  ROUTE_PORTFOLIO_PUBLIC,
  ROUTE_PORTFOLIO_LINKING_SUCCESS,
  ROUTE_PORTFOLIO_NEW,
  ROUTE_PORTFOLIO_NARRATIVES,
  ROUTE_PORTFOLIO_HOLDINGS,
  ROUTE_PORTFOLIO_ANALYSIS,
  ROUTE_PORTFOLIO_DIVIDENDS,
  ROUTE_PORTFOLIO_RETURNS,
  ROUTE_PORTFOLIO_TRANSACTIONS_EDIT_HOLDING,
  ROUTE_PORTFOLIO_TRANSACTIONS_CREATE,
  ROUTE_PORTFOLIO_TRANSACTIONS_DISCARD_PORTFOLIO,
  ROUTE_PORTFOLIO_TRANSACTIONS_EDIT,
  ROUTE_PORTFOLIO_TRANSACTIONS_SAVE_SUCCESS,
  ROUTE_PRIVACY_POLICY,
  ROUTE_PRIVACY_POLICY_FULL,
  ROUTE_PRO_API,
  ROUTE_STOCKS_HOME,
  ROUTE_STOCKS_MARKET,
  ROUTE_STOCKS_MARKET_INDUSTRY,
  ROUTE_TERMS_AND_CONDITIONS,
  ROUTE_TERMS_AND_CONDITIONS_FULL,
  ROUTE_USER_BETA_LAB,
  ROUTE_USER_LOGOUT,
  ROUTE_USER_NOTIFICATIONS,
  ROUTE_USER_PLAN,
  ROUTE_USER_PROFILE,
  ROUTE_USER_REGISTER,
  ROUTE_USER_SUBSCRIPTION,
  ROUTE_USER_SUBSCRIPTION_AND_BILLING,
  ROUTE_USER_UNSUBSCRIBE,
  ROUTE_WATCHLIST,
  ROUTE_WATCHLIST_WITH_EVENTS,
  ROUTE_WELCOME,
  ROUTE_LOCALE_COMPANY_WITH_EVENTS,
  ROUTE_LOCALE_COMPANY,
  ROUTE_PUBLIC_NARRATIVE_CREATE,
  ROUTE_PUBLIC_NARRATIVE_EDIT,
  ROUTE_PARTNER_CLAIM,
  ROUTE_SUBSCRIPTION_EXTENSION,
  ROUTE_PASSWORD_RESET,
  ROUTE_SOCIAL_TO_LOCAL,
  ROUTE_EMAIL_CHANGE,
  ROUTE_NARRATIVE_COMMUNITY,
  ROUTE_NARRATIVE_COMMUNITY_DETAIL,
  ROUTE_NARRATIVE_COMMUNITY_PROFILE,
  ROUTE_PARTNER_AVANZA_CLAIM,
  ROUTE_PARTNER_AVANZA_ERROR,
  ROUTE_PARTNER_AVANZA_LOGIN,
  ROUTE_PARTNER_AVANZA_REGISTER,
  ROUTE_GIFT_CARD_REDEEM,
  ROUTE_GIFT_CARD_PURCHASE,
  ROUTE_WELCOME_LOCALE,
  ROUTE_USER_REGISTER_LOCALE,
  ROUTE_ACCOUNT_VALIDATION_LOCALE,
  ROUTE_ACCOUNT_RECOVERY_LOCALE,
} from '@/constants/routes';
import { fetchCompany } from '@/pages/CompanyReport/redux/routines';
import { fetchPortfolio } from '@/components/DeprecatedPortfolio/redux/routines';

const components = {
  [NOT_FOUND]: 'NotFound',
  [ROUTE_COMPANY]: 'CompanyReport',
  [ROUTE_LOCALE_COMPANY]: 'CompanyReport',
  [ROUTE_COMPANY_WITH_EVENTS]: 'CompanyReport',
  [ROUTE_LOCALE_COMPANY_WITH_EVENTS]: 'CompanyReport',
  [ROUTE_COMPARE_COMPANY]: 'CompareCompanyContainer',
  [ROUTE_COMPARE_HOME]: 'CompareCompanyContainer',
  [ROUTE_CREATE_CRITERIA]: 'Screener',
  [ROUTE_EDIT_USER_IDEA]: 'Screener',
  [ROUTE_EDIT_EXPLORE_IDEA]: 'Screener',
  [ROUTE_DEPRECATED_IMPORT_PORTFOLIO]: 'DeprecatedPortfolioImportContainer',
  [ROUTE_OOPS]: 'OopsContainer',
  [ROUTE_PORTFOLIO]: 'Portfolio',
  [ROUTE_PORTFOLIO_HOLDINGS]: 'PortfolioHoldings',
  [ROUTE_PORTFOLIO_NARRATIVES]: 'PortfolioNarratives',
  [ROUTE_PORTFOLIO_ANALYSIS]: 'PortfolioAnalysis',
  [ROUTE_PORTFOLIO_DIVIDENDS]: 'PortfolioDividends',
  [ROUTE_PORTFOLIO_RETURNS]: 'PortfolioReturns',
  [ROUTE_PORTFOLIO_NEW]: 'NewPortfolio',
  [ROUTE_PORTFOLIO_TRANSACTIONS_CREATE]: 'PortfolioTransactionsCreate',
  [ROUTE_PORTFOLIO_TRANSACTIONS_DISCARD_PORTFOLIO]:
    'PortfolioTransactionsDiscardPortfolio',
  [ROUTE_PORTFOLIO_TRANSACTIONS_EDIT]: 'PortfolioTransactionsEdit',
  [ROUTE_PORTFOLIO_TRANSACTIONS_SAVE_SUCCESS]:
    'PortfolioTransactionsSaveSuccess',
  [ROUTE_PORTFOLIO_TRANSACTIONS_EDIT_HOLDING]:
    'PortfolioTransactionsEditHolding',
  [ROUTE_PORTFOLIO_DEMO]: 'DemoPortfolio',
  [ROUTE_PORTFOLIO_PUBLIC]: 'PublicPortfolio',
  [ROUTE_PORTFOLIO_DEFAULT]: 'DefaultPortfolio',
  [ROUTE_PORTFOLIO_LINKING_SUCCESS]: 'PortfolioLinkingSuccess',
  [ROUTE_DEPRECATED_PORTFOLIO_EDIT_TXN]: 'DeprecatedPortfolioEditTxnContainer',
  [ROUTE_DEPRECATED_PORTFOLIO_SHARE]: 'DeprecatedPortfolioContainer',
  [ROUTE_DEPRECATED_PORTFOLIO_LIST]: 'PortfolioLanding',
  [ROUTE_MY_PORTFOLIOS]: 'MyPortfolios',
  [ROUTE_DEPRECATED_PORTFOLIO_RETURNS_REPORT]:
    'DeprecatedPortfolioReturnsContainer',
  [ROUTE_DEPRECATED_PORTFOLIO]: 'DeprecatedPortfolioContainer',
  [ROUTE_DEPRECATED_PUBLIC_PORTFOLIO]: 'DeprecatedPortfolioContainer',
  [ROUTE_USER_PROFILE]: 'UserProfileContainer',
  [ROUTE_USER_NOTIFICATIONS]: 'UserNotificationsContainer',
  [ROUTE_USER_PLAN]: 'UserPlanContainer',
  [ROUTE_USER_SUBSCRIPTION]: 'UserSubscriptionContainer',
  [ROUTE_USER_SUBSCRIPTION_AND_BILLING]: 'UserSubscriptionAndBillingContainer',
  [ROUTE_USER_BETA_LAB]: 'UserBetaLabContainer',
  [ROUTE_DISCOVER_INVESTING_IDEAS]: 'DiscoverHubContainer',
  [ROUTE_DISCOVER_INVESTING_IDEAS_MARKET]: 'DiscoverHubContainer',
  [ROUTE_DISCOVER_SAMPLE_PORTFOLIOS]: 'DiscoverHubContainer',
  [ROUTE_INVESTING_IDEA_MARKET]: 'DiscoverPageV1',
  [ROUTE_INVESTING_IDEA_MARKET_INDUSTRY]: 'DiscoverPageV1',
  [ROUTE_WELCOME]: 'Welcome',
  [ROUTE_WELCOME_LOCALE]: 'Welcome',
  [ROUTE_USER_REGISTER]: 'Welcome',
  [ROUTE_USER_REGISTER_LOCALE]: 'Welcome',
  [ROUTE_PARTNER_CLAIM]: 'PartnerClaim',
  [ROUTE_PARTNER_REGISTER]: 'PartnerRegister',
  [ROUTE_PARTNER_LOGIN]: 'PartnerLogin',
  [ROUTE_PARTNER_ERROR]: 'PartnerError',
  [ROUTE_PARTNER_AVANZA_CLAIM]: 'PartnerAvanzaClaim',
  [ROUTE_PARTNER_AVANZA_REGISTER]: 'PartnerAvanzaRegister',
  [ROUTE_PARTNER_AVANZA_LOGIN]: 'PartnerAvanzaLogin',
  [ROUTE_PARTNER_AVANZA_ERROR]: 'PartnerAvanzaError',
  [ROUTE_DEPRECATED_PORTFOLIO_HOME]: 'DeprecatedPortfolioContainer',
  [ROUTE_ACCOUNT_RECOVERY]: 'AccountRecoveryContainer',
  [ROUTE_ACCOUNT_RECOVERY_LOCALE]: 'AccountRecoveryContainer',
  [ROUTE_OTP_LOGIN]: 'OTPLoginContainer',
  [ROUTE_DELETE_ACCOUNT]: 'OffboardingContainer',
  [ROUTE_STOCKS_HOME]: 'Stocks',
  [ROUTE_STOCKS_MARKET]: 'Stocks',
  [ROUTE_STOCKS_MARKET_INDUSTRY]: 'Stocks',
  [ROUTE_MAINTENANCE]: 'Maintenance',
  [ROUTE_PLANS]: 'Plans',
  [ROUTE_DASHBOARD]: 'DashboardContainer',
  [ROUTE_DASHBOARD_WITH_EVENTS]: 'DashboardContainer',
  [ROUTE_HOME]: 'Home',
  [ROUTE_CAREERS]: 'Careers',
  [ROUTE_ABOUT]: 'About',
  [ROUTE_WATCHLIST]: 'Watchlist',
  [ROUTE_WATCHLIST_WITH_EVENTS]: 'Watchlist',
  [ROUTE_PODCASTS]: 'Podcasts',
  [ROUTE_TERMS_AND_CONDITIONS]: 'TermsAndConditions',
  [ROUTE_TERMS_AND_CONDITIONS_FULL]: 'TermsAndConditions',
  [ROUTE_PRIVACY_POLICY]: 'PrivacyPolicy',
  [ROUTE_PRIVACY_POLICY_FULL]: 'PrivacyPolicy',
  [ROUTE_ARTICLE]: 'Article',
  [ROUTE_MARKET]: 'Market',
  [ROUTE_MARKET_HUB]: 'MarketHub',
  [ROUTE_MARKET_HOME]: 'Market',
  [ROUTE_MARKET_SECTOR]: 'Market',
  [ROUTE_MARKET_SECTOR_SECONDARY]: 'Market',
  [ROUTE_MARKET_SECTOR_SECONDARY_TERTIARY]: 'Market',
  [ROUTE_USER_UNSUBSCRIBE]: 'Unsubscribe',
  [ROUTE_AFFILIATE_PROGRAM]: 'AffiliateProgram',
  [ROUTE_ACCOUNT_VALIDATION]: 'AccountValidation',
  [ROUTE_ACCOUNT_VALIDATION_LOCALE]: 'AccountValidation',
  [ROUTE_PRO_API]: 'ProApi',
  [ROUTE_USER_LOGOUT]: 'Logout',
  [ROUTE_NARRATIVE]: 'NarrativeLegacy',
  [ROUTE_NARRATIVE_COMMUNITY_DETAIL]: 'Narrative',
  [ROUTE_PUBLIC_NARRATIVE_CREATE]: 'PublicNarrativeCreate',
  [ROUTE_PUBLIC_NARRATIVE_EDIT]: 'PublicNarrativeEdit',
  [ROUTE_NARRATIVE_COMMUNITY]: 'NarrativeCommunity',
  [ROUTE_NARRATIVE_COMMUNITY_PROFILE]: 'NarrativeCommunityProfile',
  [ROUTE_SUBSCRIPTION_EXTENSION]: 'SubscriptionExtension',
  [ROUTE_PASSWORD_RESET]: 'PasswordReset',
  [ROUTE_SOCIAL_TO_LOCAL]: 'SocialToLocal',
  [ROUTE_EMAIL_CHANGE]: 'EmailChange',
  [ROUTE_GIFT_CARD_REDEEM]: 'GiftCardRedeem',
  [ROUTE_GIFT_CARD_PURCHASE]: 'GiftCardPurchase',
} as const;

export type Components = typeof components;

export interface State {
  current: Components[keyof Components];
  pageStatus: number;
  message?: string;
  exception?: {
    title: string;
    error: string;
    reason: string;
    linked: boolean;
  };
}

const getDefault = (): State => ({
  current: components[ROUTE_WELCOME],
  pageStatus: 200,
});

interface RouteAction {
  type?: string;
  payload?: unknown;
}

function reducer(
  state = getDefault(),
  action: RouteAction | ReturnType<(typeof fetchCompany)['failure']>
) {
  return produce(state, (draft) => {
    const { type } = action;
    switch (type) {
      case fetchCompany.FAILURE: {
        const { payload } = action as ReturnType<
          (typeof fetchCompany)['failure']
        >;
        if (payload.status) {
          draft.pageStatus = payload.status;
        }
        break;
      }
      case fetchPortfolio.FAILURE: {
        const { payload } = action as ReturnType<
          (typeof fetchPortfolio)['failure']
        >;
        if (typeof payload !== 'undefined' && payload.status) {
          draft.pageStatus = payload.status;
          draft.exception = {
            title: payload.title,
            error: payload.error,
            reason: payload.reason,
            linked: payload.linked,
          };
        } else {
          delete draft.exception;
        }
        break;
      }
      /**
       * Helpful links in <NotFound>
       * Routes navigated to from the NotFound page will fail to properly display unless they are defined as part of
       * this case logic.
       */
      case ROUTE_HOME:
      case ROUTE_DASHBOARD:
      case ROUTE_WELCOME:
      case ROUTE_STOCKS_HOME:
      case ROUTE_PORTFOLIO_DEMO:
      case ROUTE_DISCOVER_INVESTING_IDEAS:
      case ROUTE_NARRATIVE_COMMUNITY:
      case ROUTE_NARRATIVE_COMMUNITY_DETAIL:
        draft.current = components[type];
        draft.pageStatus = 200;
        break;
      default:
        if (type && type in components) {
          draft.current = components[type];
          switch (type) {
            case ROUTE_MAINTENANCE:
              draft.pageStatus = 503;
              break;
            case NOT_FOUND:
              draft.pageStatus = 404;
              break;
          }
        }
        break;
    }
  });
}

export default reducer;
