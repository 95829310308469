import { refreshToken } from '@simplywallst/services';
import { differenceInSeconds } from 'date-fns';
import type { CookieOptions } from 'express-serve-static-core';
import { AUTH_SCOPE, SWS_CLIENT_ID } from '../constants/env';

export const canReadWriteCookies = () =>
  typeof navigator !== 'undefined' && navigator.cookieEnabled === true;

export enum AppCookies {
  AUTH = 'auth',
  MARKET = 'market',
  PORTFOLIO_ID = 'portfolioId',
  PORTFOLIO_HOLDINGS_SORT_TERM = 'portfolioHoldingsSortTerm',
  PORTFOLIO_HOLDINGS_HIDE_LIQUIDATED = 'portfolioHoldingsHideLiquidated',
  REGISTRATION_FROM_NEWS = 'registrationFromNews',
}

export function getCookie(id: string) {
  const cookieStr = document.cookie.split(';').find((str) => {
    const cookieProp = str.split('=')[0].trim();
    return cookieProp === id;
  });
  if (cookieStr) {
    return cookieStr.split('=')[1];
  }
}

export interface Flag {
  flagKey: string;
  flagId: string;
  variantId?: string;
  variantKey?: string;
}

export interface FlagCookie {
  entityId?: string;
  flags?: Flag[];
}

export const isAccessTokenExpiring = (expiresAt: number) => {
  const diff = differenceInSeconds(expiresAt, Date.now());
  return diff < 30;
};

export const convertExpiresInToExpiresAt = (expiresIn = 604800000) => {
  const now = new Date();
  now.setSeconds(now.getSeconds() + expiresIn);
  return Number(now);
};

export const getNextYear = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  return date;
};

export const getExpiryTime = () => {
  return `expires=${getNextYear().toUTCString()}`;
};

export const clearAllCookies = async () => {
  if (canReadWriteCookies()) {
    for (const [, cookie] of Object.entries(AppCookies)) {
      document.cookie = `${cookie}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
    for (const [, cookie] of Object.entries(AppCookies)) {
      document.cookie = `${cookie}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.simplywall.st`;
    }
  }
  if (RUNTIME_ENV === 'native') {
    try {
      const { Preferences } = await import('@capacitor/preferences');
      await Preferences.remove({
        key: 'redux.tokens',
      });
    } catch (error) {
      //
    }
  }
};

export const setAuthCookie = async (
  accessToken: string,
  refreshToken: string,
  accessTokenExpiry = 604800000
) => {
  if (RUNTIME_ENV === 'native') {
    try {
      const { Preferences } = await import('@capacitor/preferences');
      Preferences.set({
        key: 'redux.tokens',
        value: JSON.stringify({
          accessToken,
          refreshToken,
          expiresAt: accessTokenExpiry,
        }),
      });
    } catch (error) {
      //
    }
  }
  if (canReadWriteCookies()) {
    const encodedContent = encodeURIComponent(
      `${accessToken}|${refreshToken}|${accessTokenExpiry}`
    );
    document.cookie = `${
      AppCookies.AUTH
    }=${encodedContent};${getCookieOptions()}`;
  }
};

export interface AuthTokens {
  accessToken?: string;
  refreshToken: string;
  expiresAt: number;
}

export async function doRefreshAccessToken(authCookie: AuthTokens) {
  const response = await refreshToken({
    refresh_token: authCookie.refreshToken,
    grant_type: 'refresh_token',
    client_id: SWS_CLIENT_ID || '',
    scope: AUTH_SCOPE || '',
  });
  const { access_token, expires_in, refresh_token } = response.data;
  const expiresAt = convertExpiresInToExpiresAt(expires_in);
  setAuthCookie(access_token, refresh_token, expiresAt);
  return {
    accessToken: access_token,
    refreshToken: refresh_token,
    expiresAt,
  };
}

function objectIsAuthToken(obj: unknown): obj is AuthTokens {
  if (!obj || typeof obj !== 'object') {
    return false;
  }
  return (
    'accessToken' in obj &&
    typeof obj.accessToken === 'string' &&
    'refreshToken' in obj &&
    typeof obj.refreshToken === 'string' &&
    'expiresAt' in obj &&
    typeof obj.expiresAt === 'number'
  );
}

export const getAuthCookie = async (
  first = true
): Promise<AuthTokens | undefined> => {
  const refreshAndReturn = async (authCookie: AuthTokens) => {
    await doRefreshAccessToken(authCookie);

    if (!first) {
      throw new Error('Failed to refresh and provide auth cookie.');
    }

    return getAuthCookie(false);
  };

  if (RUNTIME_ENV === 'native') {
    const { Preferences } = await import('@capacitor/preferences');
    try {
      const tokens = await Preferences.get({
        key: 'redux.tokens',
      });
      const { value } = tokens;

      if (value === null) {
        throw new Error('No tokens found');
      }

      const token = JSON.parse(value);

      if (!objectIsAuthToken(token)) {
        throw new Error('Token shape is incorrect');
      }
      const expiresAt = Number(token.expiresAt);
      if (isAccessTokenExpiring(expiresAt)) {
        return refreshAndReturn(token);
      }
      return {
        ...token,
        expiresAt,
      };
    } catch (error) {
      await Preferences.remove({ key: 'redux.tokens' });
      return undefined;
    }
  }
  if (canReadWriteCookies()) {
    const rawAuthStr = getCookie(AppCookies.AUTH);
    if (rawAuthStr) {
      const [accessToken, refreshToken, expiresAt] =
        decodeURIComponent(rawAuthStr).split('|');
      const token = { accessToken, refreshToken, expiresAt: Number(expiresAt) };
      if (isAccessTokenExpiring(token.expiresAt)) {
        try {
          const response = await refreshAndReturn(token);
          if (typeof response === 'undefined') {
            throw new Error('Response is undefined');
          }
          return response;
        } catch (error) {
          /**
           * On refresh exception, nuke the cookie
           * return undefined
           */
          await clearAllCookies();
          return undefined;
        }
      }
      return { accessToken, refreshToken, expiresAt: Number(expiresAt) };
    } else {
      /**
       * In case auth cookie is falsey. Nuke the cookies.
       * No need to return undefined, default js behaviour.
       * */
      await clearAllCookies();
    }
  }
};

export const getCookieOptions = (): string | CookieOptions => {
  if (RUNTIME_ENV === 'server') {
    return {
      expires: getNextYear(),
      httpOnly: false,
      path: '/',
    };
  } else {
    const expiryTime = getExpiryTime();
    return `${expiryTime};path=/;SameSite;`;
  }
};

export const getRegistrationFromNewsCookie = (): boolean => {
  if (canReadWriteCookies()) {
    const cookie = getCookie(AppCookies.REGISTRATION_FROM_NEWS);
    if (cookie) {
      return true;
    }
    return false;
  }
  return false;
};
